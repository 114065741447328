import $ from "jquery";

function scrollToElement($el) {
  if ($el.length <= 0) {
    return;
  }

  let el = $el;

  // calculate navbar offset
  const navBars = document.getElementsByClassName("js-navbar");
  const navBar = navBars && navBars[0];
  const navOffset = navBar ? navBar.getBoundingClientRect().height : 0;

  if (el.hasClass("js-collapsible")) {
    // keep only one accordion open when not in multiple mode
    if (!el.hasClass("js-collapsible-multiple")) {
      const extendedAccordion = document
        .getElementsByClassName("js-collapsible collapse show")
        .item(0);

      if (extendedAccordion) {
        const header = extendedAccordion.parentNode.children[0].children[0];
        header.classList.add("collapsed");
        extendedAccordion.classList.remove("show");
      }
    }

    el = el.parent();
  }

  $("html, body").animate(
    {
      // smooth scroll to element using linear ease mode
      scrollTop: el.offset().top - navOffset,
    },
    400,
    "linear"
  );
}

/**
 * @param {string} target
 */
export function scrollToHash(target) {
  // ignore any string before the hash
  const hash = target.substring(target.indexOf("#") + 1);
  if (!hash) {
    return;
  }

  const element = document.getElementById(hash);
  scrollToElement($(element));
}

export function scrollToId(id) {
  const $el = $(`#${id}`);

  scrollToElement($el);
}

function scrollToHashOnPageLoad() {
  if (window.location.hash) {
    const errorField = getInvalidFormElement();

    if (errorField) {
      scrollToElement(errorField);
    } else {
      scrollToHash(window.location.hash);
    }
  }
}

function getInvalidFormElement() {
  let errorFields = [];

  if (
    window.location.search &&
    window.location.search.match(/tx_form_formframework/)
  ) {
    const $el = $(window.location.hash);
    errorFields = $el.find(".form-group.has-error");
  }

  return errorFields.length ? $(errorFields[0]) : false;
}

$(document).ready(() => {
  // catch all internal links containing a hash ...
  $("a[href*='#']:not(a[href^=http]):not([class^='carousel-control'])").click(
    function () {
      // ... and scroll to the element with the same hash id
      scrollToHash(this.href);
    }
  );

  // auto scroll on page load
  // time out is needed for larger images to fully load
  // without timeout coordinates may be wrong
  // this value might need tweaking
  window.setTimeout(scrollToHashOnPageLoad, 800);
});
